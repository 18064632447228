import { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import generate_random_colors from 'rand-hex-color';

import './App.css';

function generate_color_js_code(colors) {
  if (!colors.length) {
    return "";
  }

  if (colors.length > 1) {
    return `const colors = [${colors.map(x => `"${x}"`).join(', ')}];`;
  }

  return `const color = "${colors[0]}";`
}

function App() {
  const [colors, setColors] = useState([
    generate_random_colors(),
  ]);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false)
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [copied])

  return (
    <>
    <h1 style={{backgroundImage: `linear-gradient(to right, ${colors.join(", ")})`}}  className={`${colors.length > 1 ? "transparent-text" : ""}`}>Random hex color generator</h1>
      <div className="card">
        <button onClick={() => setColors(old => [...old, generate_random_colors()])}>
          Number of colors ({colors.length})
        </button>
        {' '}
        <CopyToClipboard text={generate_color_js_code(colors)}
          onCopy={() => setCopied(true)}>
          <button>copy colors {copied ? <span style={{color: 'green'}}>{'   '}&#10003;</span> : null}</button>
        </CopyToClipboard>
      </div>
      <div className="card grid-container">
        {
          colors.map((color, index) => {
            return (
              <div key={index} className='grid-item' style={{
                backgroundColor: color,
                padding: "10px",
                marginRight: "2px",
                cursor: "pointer",
              }} onClick={e => setColors(old => {
                let copy = [...old]
                copy.splice(index, 1)
                return copy;
              })}>{color}</div>
            )
          })
        }
      </div>
    </>
  )
}

export default App